@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
.vr-nav {
  background-color: transparent !important;
}
html, body {
  overflow-x: hidden;
}
.vr-bg {
  
}
.vr-navmobile {
  background: #393E41;
  color: #FFF;
}
.vr-navmobile a:hover,
.vr-navmobile a:focus {
  color: #FFBF46;
}
.dropdown-menu {
  background-color: #2F2F2F;
}
.dropdown-menu a {
  color: #FFF;
}
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #2F2F2F;
}
.vr-brand {
  color: #FFBF46;
  font-family: Play;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.vr-nav_text {
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.vr-header_title {
  color: #FFF;
  font-family: Play;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.vr-header_title span {
  color: #FFBF46;
}
.vr-header_title_main {
  color: #FFF;
  font-family: Play;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  text-shadow: 10px 10px 10px rgba(47, 47, 47, .5), 1.5px 1.5px 1.5px rgba(47, 47, 47, .7);
}
.vr-header_title_main span {
  color: #FFBF46;
}
.vr-header_text {
  color: #FFF;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 22px;
}
.vr-header_text_main {
  color: rgba(255,255,255, 1);
  font-family: Play;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 35px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  text-shadow: 10px 10px 10px rgba(47, 47, 47, .8), 1.5px 1.5px 1.5px rgba(0,0,0, .7);
}
.vr-header_title_games {
  color: #FFBF46;
  font-family: Play;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding-bottom: 15px;
}
.vr-header_games_card {
  border-radius: 20px;
  margin-top: 18px;
  background: #2F2F2F;
}
.vr-header_games_card:hover {
  background: #393E41;
  cursor: pointer;
  box-shadow: 2px 2px 2px #FFBF46;
}
.vr-header_games_card_picture {
  border-radius: 10px;
  max-width: 53px;
  max-height: 53px;
  margin: 10px 13px;
}
.vr-header_games_card_header {
  color: #FFF;
  font-family: Roboto Condensed;
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.vr-header_games_card_text {
  margin: 0;
  color: #FFF;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.vr-header_games_card_info {
  margin: 16px 16px;
}
.vr_header_games_block {
  margin-left: auto;
  margin-right: 16px;
}
.vr-header_games_arrow {
  font-size: 43px;
  color: rgba(255, 191, 70, 0.7);
  flex-shrink: 0;
  margin: 14px 0px;
}
.vr-header_games_card:hover .vr-header_games_arrow {
  color: rgba(255, 191, 70, 1);
}
.vr-main {
  /* margin-top: 103px;
  height: 90vh; */
}
.vr-header_main {
  /*margin-top: 85px;*/
}
.vr-btn {
  height: 31px;
  border-radius: 10px;
  background: #FFBF46;
  color: #393E41;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.vr-btn_alt {
  margin-left: 28px;
  border-radius: 10px;
  background: #2F2F2F;
  height: 31px;
  color: #FFBF46;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 1px solid #2F2F2F;
}
.vr-btn_alt_2 {
  margin-left: 28px;
  border-radius: 10px;
  background: #393E41;
  height: 31px;
  color: #FFBF46;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 1px solid #393E41;
}
.vr-header_btn_group {
  margin-top: 32px;
}
.vr_header_games_link {
  color: #FFF;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 17px;
  text-align: center;
}
.vr-arrow-box {
  height: 50px;
}
.vr-header_bottom_arrow {
  font-size: 55px;
  color: rgba(255, 191, 70, 0.7);
  flex-shrink: 0;
  margin-top: 246px;
  animation: arrowAnimation 2s infinite;
}
.vr-header_bottom_arrow:hover {
  color: rgba(255, 191, 70, 1);
}
@keyframes arrowAnimation {
  50% {margin-top: 196px;}
}
.vr-games_title {
  margin-top: 50px;
  color: #FFF;
  font-family: Play;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.vr-games_title span {
  color: rgba(255, 191, 70, 1);
}
.vr-games_text {
  color: #FFF;
  text-align: center;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
}
.vr-games_card {
  max-width: 348px;
  background: #2F2F2F;
  border-radius: 20px;
  padding: 14px;
}
.vr-games_card_img {
  width: 320px;
  height: 320px;
  object-fit: cover;
  margin: 0;
  border-radius: 10px;
}
.vr-games_card_title {
  color: #FFBF46;
  font-family: Play;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.vr-games_card_cost {
  color: #FFF;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.paginate-btn-active {
  background: no-repeat #FFBF46 !important;
}
.paginate-btn-active .page-link {
  background: no-repeat #FFBF46 !important;
  border-color: #2F2F2F !important;
}
.page-link  {
  background-color: #2F2F2F;
  border: 1px solid #2F2F2F;
}
.disabled > .page-link {
  background: #393E41;
  border: 1px solid #393E41;
  color: #fff;
}
.paginate-btn-active .page-link:focus {
  box-shadow: 0 0 0 3px rgba(255, 191, 70, 0.2) !important;
}
.page-item > .page-link:focus {
  box-shadow: 0 0 0 3px rgba(255, 191, 70, 0.2) !important;
}
.page-item .disabled {
  background: red;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  background: #FFBF46;
  color: #2F2F2F
}

.page-link {
  color: white;
}

.vr-games_card_info {
  margin-top: 21px;
}
.vr-games_card_shortdesc {
  color: #FFF;
  font-family: Roboto Condensed;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.vr-games_cards {
  margin-top: 63px;
}
.vr-games-cards_footer {
  padding-bottom: 20px;
}
.footer {
  background: rgb(57, 62, 65);
}
.vr-room {
  margin-top: 150px;
}
.bubble-lab {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.vr-game {
  margin-top: 42px;
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}
.vr-game_logotype {
  max-width: 620px;
  border-radius: 20px;
}
.vr-game_cost_discount {
  color: #FFFFFF;
}
.vr-game_cost_discount span {
  color: #FFBF46;
}
.vr-game_cost {
  color: #FFFFFF;
  font-family: Play;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.vr-game_cost span {
  color: #FFBF46;
}
.vr-game_slider_box {
  margin-top: 20px;
}
.btn-close {
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFBF46%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
}
.vr-social {
  height: 480px;
  border-radius: 20px;
}
.vr-social_content {
  margin-top: 150px;
}
.vr-howtogame_item {
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: 100px;
  border-radius: 30px;
  background-color:rgba(47, 47, 47, .5);
  padding: 25px;
}
.vr-howtogame_item_alt {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  margin-top: 25px;
}
.vr-howtogame_item_icon_alt {
  font-size: 50px;
  text-align: center;
  color: white;
}
.bg_fixed {
  background-attachment: fixed;
  background-position: center;
}
.vr-howtogame_item_icon {
  color: rgba(255, 191, 70, 0.7);
  font-size: 50px;
  text-align: center;
}
.vr-howtogame_item_icon:hover {
  color:#FFBF46;
}
.vr-howtogame_item_header {
  margin: 0;
  margin-top: 20px;
  color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #FFF;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.vr-howtogame {
  margin-top: 150px;
  margin-bottom: 150px;
}
.vr-notfound {
  margin-top: 60px;
}

@media screen and (max-width: 1920px) {
  .vr-main {
    margin-top: 103px;
    height: 90vh;
  }
  .vr-header_main {
    margin-top: 85px;
  }
  .vr-game_slider {
    height: 350px;
    object-fit: cover;
    border-radius: 20px;
  }
}
@media screen and (max-width: 1440px) {
  .vr-game_logotype {
    max-width: 525px;
    border-radius: 20px;
  }
  .vr-main {
    margin-top: 103px;
    height: 120vh;
  }
}
@media screen and (max-width: 1399px) {
  .vr-game_logotype {
    max-width: 525px;
    border-radius: 20px;
  }
  .vr-main {
    margin-top: 103px;
    height: 120vh;
  }
}
@media screen and (max-width: 1280px) {
  .vr-main {
    margin-top: 103px;
    height: 120vh;
  }
}
@media screen and (max-width: 1024px) {
  .vr-social svg {
    display: none;
  }
  .vr-game_logotype {
    max-width: 430px;
    border-radius: 20px;
  }
  .vr-main {
    margin-top: 103px;
    height: 120vh;
  }
  .vr-header_main {
    margin-top: 85px;
  }
  .vr-game_slider {
    height: 350px;
    object-fit: cover;
    border-radius: 20px;
  }
}
@media screen and (max-width: 991px) {
  .vr-howtogame_item_alt-box {
    display: none;
  }
  .vr-game_logotype {
    max-width: 327px;
    border-radius: 20px;
  }

  .vr-header_bottom_arrow {
    display: none;
  }
  .vr-social svg {
    display: none;
  }
  .vr-games {
    margin-top: 100px;
  }
  .vr-header_title_games {
    margin-top: 20px;
  }
  .vr-howtogame_item_alt-box {
    display: none;
  }
  .vr-howtogame_item_alt-box {
    display: none;
  }
  .vr-header_title {
    margin-top: 50px;
  }
  .vr-header_main {
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .vr-header_bottom_arrow {
    display: none;
  }
  .vr-social svg {
    display: none;
  }
  .vr-games {
    margin-top: 100px;
  }
  .vr-header_title_games {
    margin-top: 20px;
  }
  .vr-howtogame_item_alt-box {
    display: none;
  }
  .vr-howtogame_item_alt-box {
    display: none;
  }
  .vr-header_title {
    margin-top: 50px;
  }
  .vr-header_main {
    margin-top: 10px;
  }
}
@media screen and (max-width: 640px) {
  .vr-header_bottom_arrow {
    display: none;
  }
  .vr-social svg {
    display: none;
  }
  .vr-games {
    margin-top: 100px;
  }
  .vr-header_title_games {
    margin-top: 20px;
  }
  .vr-howtogame_item_alt-box {
    display: none;
  }
  .vr-game_logotype {
    max-width: 515px;
    border-radius: 20px;
  }
  .vr-header_title {
    margin-top: 50px;
  }
  .vr-main {
    margin-top: 103px;
    height: 100vh;
  }
  .vr-header_main {
    margin-top: 10px;
  }
}
@media screen and (max-width: 365px) {
  .vr-social svg {
    display: block;
  }
  .vr-social_content {
    margin-top: 25px;
  }
  .vr-howtogame_item_header {
    font-size: 14px;
  }
  .vr-social_content svg {
    margin-top: 25px;
  }
  .vr-header_bottom_arrow {
    display: none;
  }
  .vr-howtogame_item_alt-box {
    display: none;
  }
  .vr-header_title {
    color: #FFF;
    font-family: Play;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
  }
  .vr-game_cost {
    color: #FFFFFF;
    font-family: Play;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  .vr-game_logotype {
    max-width: 335px;
    border-radius: 20px;
  }
  .vr-header_games_card_header {
    font-size: 16px;
  }
  .vr-header_title_games {
    margin-top: 100px;
  }
  .vr-main {
    margin-top: 50px;
    height: 120vh;
  }
  .vr-header_main {
    margin-top: 0px;
  }
}